export const newsLettersUrls: { [key: string]: string } = {
  '0': 'https://online.fliphtml5.com/xjgcf/erhs',
  '1': 'https://online.fliphtml5.com/xjgcf/gtiy',
  '2': 'https://online.fliphtml5.com/xjgcf/ukxg',
  '3': 'https://online.fliphtml5.com/xjgcf/yfgk',
  '4': 'https://online.fliphtml5.com/xjgcf/ikcv',
  '5': 'https://online.fliphtml5.com/xjgcf/fjyj/',
  '6': 'https://online.fliphtml5.com/xjgcf/rlby/',
  '7': 'https://online.fliphtml5.com/xjgcf/vxmy/',
  '8': 'https://online.fliphtml5.com/xjgcf/ofqz/',
  '9': 'https://online.fliphtml5.com/xjgcf/mrwy/',
};
